import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, withStyles } from "@material-ui/core";
import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import LayoutBase from "../../../layouts/LayoutBase";
import { useAuthenticatedHttpClient } from "../../../utils/authenticated-api-call";
import toast, { Toaster } from "react-hot-toast";
import LoadingSpinner from "../../../partials/LoadingSpinner";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

function AdminListEvent() {
  const { internalAPIClient } = useAuthenticatedHttpClient();
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletingId, setDeletingId] = useState();

  useEffect(async () => {
    await internalAPIClient
      .get(`/cms/missions`)
      .then((res) => {
        setRows(res?.data?.data || []);
      })
      .catch(async (e) => {
        console.log(e);
      });
  }, []);

  const handleDeleteData = async () => {
    setIsLoading(true);
    
    await internalAPIClient
      .delete(`/cms/missions/${deletingId}`)
      .then((res) => {
        if (res?.data?.status === 'success') {
          toast.success("Success delete data");
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        } else {
          console.log(res);
          toast.error("Failed to delete event");
        }
      })
      .catch(async (e) => {
        console.log(e);
        toast.error("Failed to delete event");
      });

    setIsLoading(false);
  }

  return (
    <LayoutBase>
      {isLoading && <LoadingSpinner />}

      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        <div className="flex items-center justify-between">
          <h1 className="font-bold text-2xl mb-4 font-cursive">Data Event</h1>
          <Link
            to="/admin/event/create"
            className="px-5 py-2.5 bg-primary-800 text-white rounded hover:contrast-75 font-cursive text-xs mb-4 flex items-center gap-1"
          >
            <Icon icon="uil:plus" className="w-4 h-4" />
            <span>Create New</span>
          </Link>
        </div>

        <TableContainer component={Paper}>
          <Table className="w-full" aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Season</StyledTableCell>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Description</StyledTableCell>
                <StyledTableCell>Enabled</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row?.season}
                  </TableCell>
                  <TableCell>{row?.name}</TableCell>
                  <TableCell>{row?.description}</TableCell>
                  <TableCell>
                    {row?.enabled ? (
                      <Icon icon="uil:check-circle" className="w-5 h-5 text-green-500" />
                    ) : (
                      <Icon icon="uil:times-circle" className="w-5 h-5 text-red-500" />
                    )}
                  </TableCell>
                  <TableCell>
                    <div className="flex items-center gap-2">
                      <Link to={`/admin/event/${row?.id}/participant`} className="p-2 bg-primary-800 rounded-md text-white">
                        <Icon icon="uil:user" />
                      </Link>
                      <Link to={`/admin/event/${row?.id}/edit`} className="p-2 bg-primary-800 rounded-md text-white">
                        <Icon icon="uil:edit" />
                      </Link>
                      <Link to={`/admin/event/${row?.id}/story`} className="p-2 bg-primary-800 rounded-md text-white">
                        <Icon icon="uil:image" />
                      </Link>
                      <button
                        className="p-2 bg-red-500 rounded-md text-white"
                        onClick={() => {
                          setIsDeleting(true);
                          setDeletingId(row?.id);
                        }}
                      >
                        <Icon icon="uil:trash-alt" />
                      </button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <Transition appear show={isDeleting} as={Fragment}>
        <Dialog as="div" className="relative z-20" onClose={() => setIsDeleting(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    Are you sure?
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">Your data will be delete permanently!</p>
                  </div>

                  <div className="mt-4 flex items-center gap-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                      onClick={() => handleDeleteData()}
                    >
                      Delete
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => setIsDeleting(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Toaster />
    </LayoutBase>
  );
}

export default AdminListEvent;

import { Icon } from "@iconify/react";
import { Link, useNavigate, useParams } from "react-router-dom";
import LayoutBase from "../../../layouts/LayoutBase";
import { Paper } from "@material-ui/core";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as yup from "yup";
import toast, { Toaster } from "react-hot-toast";
import { Fragment, useEffect, useState } from "react";
import { useAuthenticatedHttpClient } from "../../../utils/authenticated-api-call";
import LoadingSpinner from "../../../partials/LoadingSpinner";
import moment from "moment";
import { Dialog, Transition } from "@headlessui/react";
import { confirmAlert } from "react-confirm-alert";

function AdminEditEvent() {
  const params = useParams();
  const navigate = useNavigate();
  const { internalAPIClient } = useAuthenticatedHttpClient();
  const [event, setEvent] = useState();
  const [requirements, setRequirements] = useState([]);
  const [isAddReq, setIsAddReq] = useState(false);
  const [isAddReward, setIsAddReward] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = yup.object().shape({
    season: yup.string().required(),
    name: yup.string().required(),
    category: yup.string().required(),
    mode: yup.string().required(),
    milestone_interval: yup.number().required(),
    point_per_day: yup.number().required(),
    start_block: yup.number().required(),
    description: yup.string().required(),
    event_mechanism: yup.string().required(),
    // story: yup.string().required(),
    start_time: yup.string().required(),
    end_time: yup.string().required(),
    participant_limit: yup.number().required(),
    // reward: yup.number().required(),
    // reward_name: yup.string().required(),
    // reward_description: yup.string().required(),
  });

  const validationSchemaRequirement = yup.object().shape({
    event_id: yup.string().required(),
    collection_id: yup.string().required(),
    collection_type: yup.string().required(),
    operant: yup.string().required(),
    token_id: yup.string().required(),
    amount: yup.string().required(),
  });

  const validationSchemaReward = yup.object().shape({
    collection_id: yup.number().required(),
    token_id: yup.number().required(),
    quantity: yup.number().required(),
  });

  const renderError = (message) => <p className="text-sm italic text-red-600">{message}</p>;

  useEffect(() => {
    getEvent();
  }, []);

  const getEvent = async () => {
    await internalAPIClient
      .get(`/cms/missions/${params?.id}`)
      .then((res) => {
        setEvent(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error occured");
      });
  };

  const handleSubmitEvent = async (values) => {
    setIsLoading(true);

    var formData = new FormData();
    for (var key in values) {
      formData.append(key, values[key]);
    }

    await internalAPIClient
      .put(`/cms/missions/${params?.id}`, formData)
      .then((res) => {
        if (res?.data?.status === "success") {
          toast.success("Success update event", { position: "top-right" });
          setTimeout(() => {
            return navigate(`${process.env.PUBLIC_URL}/admin/event`);
          }, 1500);
        } else {
          console.log(res);
          toast.error("Failed to update event");
        }
      })
      .catch(async (e) => {
        console.log(e);
        toast.error("Failed to update event");
      });

    setIsLoading(false);
  };

  const handleSubmitRequirement = async (values) => {
    setIsLoading(true);

    var formData = new FormData();
    for (var key in values) {
      formData.append(key, values[key]);
    }

    await internalAPIClient
      .post(`/cms/missions/${params?.id}/requirements`, formData)
      .then((res) => {
        if (res?.data?.status === "success") {
          toast.success("Success add requirement", { position: "top-right" });
          getEvent();
          setIsAddReq(false);
        } else {
          console.log(res);
          toast.error("Failed to add requirement");
        }
      })
      .catch(async (e) => {
        console.log(e);
        toast.error("Failed to add requirement");
      });

    setIsLoading(false);
  };

  const handleSubmitReward = async (values) => {
    setIsLoading(true);

    var formData = new FormData();
    for (var key in values) {
      formData.append(key, values[key]);
    }

    await internalAPIClient
      .post(`/cms/missions/${params?.id}/rewards`, formData)
      .then((res) => {
        if (res?.data?.status === "success") {
          toast.success("Success add reward", { position: "top-right" });
          getEvent();
          setIsAddReward(false);
        } else {
          console.log(res);
          toast.error("Failed to add reward");
        }
      })
      .catch(async (e) => {
        console.log(e);
        toast.error("Failed to add reward");
      });

    setIsLoading(false);
  };

  const handleDeleteRequirement = (id) => {
    confirmAlert({
      title: "Are you sure?",
      message: "The data will be permanently deleted",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            setIsLoading(true);
            await internalAPIClient
              .delete(`/cms/missions/${params?.id}/requirements/${id}`)
              .then((res) => {
                if (res?.data?.status === "success") {
                  toast.success("Success!");
                  getEvent();
                } else {
                  toast.error("Error occured!");
                }
                setIsLoading(false);
              })
              .catch((err) => {
                console.log(err);
                toast.error("Error occured!");
              });
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleDeleteReward = (id) => {
    confirmAlert({
      title: "Are you sure?",
      message: "The data will be permanently deleted",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            setIsLoading(true);
            await internalAPIClient
              .delete(`/cms/missions/${params?.id}/rewards/${id}`)
              .then((res) => {
                if (res?.data?.status === "success") {
                  toast.success("Success!");
                  getEvent();
                } else {
                  toast.error("Error occured!");
                }
                setIsLoading(false);
              })
              .catch((err) => {
                console.log(err);
                toast.error("Error occured!");
              });
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  return (
    <LayoutBase>
      {isLoading && <LoadingSpinner />}

      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        <div className="flex items-center justify-between">
          <h1 className="font-bold text-2xl mb-4 font-cursive">Edit Event</h1>
          <div className="flex items-center gap-2">
            <Link
              to="/admin/event"
              className="px-5 py-2.5 bg-primary-800 text-white rounded hover:contrast-75 font-cursive text-xs mb-4 flex items-center gap-1"
            >
              <Icon icon="uil:arrow-left" className="w-4 h-4" />
              <span>Back</span>
            </Link>
          </div>
        </div>

        <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="col-span-2">
            <Paper elevation={2} className="p-4 md:p-6">
              <Formik
                enableReinitialize
                initialValues={{
                  season: event?.season,
                  name: event?.name,
                  category: event?.category,
                  mode: event?.mode,
                  milestone_interval: event?.milestone_interval,
                  point_per_day: event?.point_per_day,
                  start_block: event?.start_block,
                  description: event?.description,
                  event_mechanism: event?.event_mechanism,
                  story: event?.story,
                  start_time: moment.utc(event?.start_time).format("YYYY-MM-DD HH:mm"),
                  end_time: moment.utc(event?.end_time).format("YYYY-MM-DD HH:mm"),
                  participant_limit: event?.participant_limit,
                  // reward: event?.reward,
                  // reward_name: event?.reward_name,
                  // reward_description: event?.reward_description,
                  enabled: event?.enabled,
                  allow_join_mid: event?.allow_join_mid
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  handleSubmitEvent(values);
                }}
              >
                {(formik) => (
                  <Form>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                      <div className="space-y-1">
                        <label htmlFor="season">Season *</label>
                        <Field name="season" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                        <ErrorMessage name="season" render={renderError} />
                      </div>
                      <div className="space-y-1">
                        <label htmlFor="name">Name *</label>
                        <Field name="name" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                        <ErrorMessage name="name" render={renderError} />
                      </div>
                      <div className="space-y-1">
                        <label htmlFor="category">Category *</label>
                        <Field as="select" name="category" className="block w-full px-4 py-2 border border-gray-300 rounded">
                          <option value="main">Main</option>
                          <option value="side">Side</option>
                        </Field>
                        <ErrorMessage name="category" render={renderError} />
                      </div>
                      <div className="space-y-1">
                        <label htmlFor="mode">Mode *</label>
                        <Field as="select" name="mode" className="block w-full px-4 py-2 border border-gray-300 rounded">
                          <option value="individual">Individual</option>
                          <option value="squad">Squad</option>
                        </Field>
                        <ErrorMessage name="mode" render={renderError} />
                      </div>
                      <div className="space-y-1">
                        <label htmlFor="milestone_interval">Milestone Interval *</label>
                        <Field name="milestone_interval" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                        <ErrorMessage name="milestone_interval" render={renderError} />
                      </div>
                      <div className="space-y-1">
                        <label htmlFor="point_per_day">Point Per Day *</label>
                        <Field name="point_per_day" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                        <ErrorMessage name="point_per_day" render={renderError} />
                      </div>
                      <div className="space-y-1 col-span-3">
                        <label htmlFor="description">Description *</label>
                        <Field as="textarea" name="description" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                        <ErrorMessage name="description" render={renderError} />
                      </div>
                      <div className="space-y-1 col-span-3">
                        <label htmlFor="event_mechanism">Event Mechanism *</label>
                        <Field as="textarea" name="event_mechanism" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                        <ErrorMessage name="event_mechanism" render={renderError} />
                      </div>
                      {/* <div className="space-y-1 col-span-3">
                        <label htmlFor="story">Story *</label>
                        <Field as="textarea" name="story" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                        <ErrorMessage name="story" render={renderError} />
                      </div> */}
                      <div className="space-y-1">
                        <label htmlFor="start_time">Start Time *</label>
                        <Field type="datetime-local" name="start_time" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                        <ErrorMessage name="start_time" render={renderError} />
                      </div>
                      <div className="space-y-1">
                        <label htmlFor="end_time">End Time *</label>
                        <Field type="datetime-local" name="end_time" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                        <ErrorMessage name="end_time" render={renderError} />
                      </div>
                      <div className="space-y-1">
                        <label htmlFor="participant_limit">Participant Limit *</label>
                        <Field type="number" name="participant_limit" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                        <ErrorMessage name="participant_limit" render={renderError} />
                      </div>
                      {/* <div className="space-y-1">
                        <label htmlFor="reward">Reward *</label>
                        <Field type="number" name="reward" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                        <ErrorMessage name="reward" render={renderError} />
                      </div>
                      <div className="space-y-1">
                        <label htmlFor="reward_name">Reward Name *</label>
                        <Field name="reward_name" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                        <ErrorMessage name="reward_name" render={renderError} />
                      </div>
                      <div className="space-y-1">
                        <label htmlFor="reward_description">Reward Description *</label>
                        <Field name="reward_description" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                        <ErrorMessage name="reward_description" render={renderError} />
                      </div> */}
                      <div className="space-y-1">
                        <label htmlFor="start_block">Start Block *</label>
                        <Field name="start_block" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                        <ErrorMessage name="start_block" render={renderError} />
                      </div>
                      <div className="space-y-1">
                        <label htmlFor="banner_image">Banner Image</label>
                        <input
                          id="banner_image"
                          name="banner_image"
                          type="file"
                          className="block w-full px-4 py-2 border border-gray-300 rounded"
                          accept="image/*"
                          onChange={(event) => {
                            formik.setFieldValue("banner_image", event.currentTarget.files[0]);
                          }}
                        />
                        <ErrorMessage name="banner_image" render={renderError} />
                      </div>
                      <div className="space-y-1">
                        <label htmlFor="background_image">Background Image</label>
                        <input
                          id="background_image"
                          name="background_image"
                          type="file"
                          className="block w-full px-4 py-2 border border-gray-300 rounded"
                          accept="image/*"
                          onChange={(event) => {
                            formik.setFieldValue("background_image", event.currentTarget.files[0]);
                          }}
                        />
                        <ErrorMessage name="background_image" render={renderError} />
                      </div>
                      <div className="space-y-1">
                        <label htmlFor="reward_image">Reward Image</label>
                        <input
                          id="reward_image"
                          name="reward_image"
                          type="file"
                          className="block w-full px-4 py-2 border border-gray-300 rounded"
                          accept="image/*"
                          onChange={(event) => {
                            formik.setFieldValue("reward_image", event.currentTarget.files[0]);
                          }}
                        />
                        <ErrorMessage name="reward_image" render={renderError} />
                      </div>
                      <div className="space-y-1">
                        <label htmlFor="reward_asset_url">Reward Asset</label>
                        <input
                          id="reward_asset_url"
                          name="reward_asset_url"
                          type="file"
                          className="block w-full px-4 py-2 border border-gray-300 rounded"
                          accept="image/*"
                          onChange={(event) => {
                            formik.setFieldValue("reward_asset_url", event.currentTarget.files[0]);
                          }}
                        />
                        <ErrorMessage name="reward_asset_url" render={renderError} />
                      </div>
                      <div className="space-y-1">
                        <label htmlFor="title_url">Title Image</label>
                        <input
                          id="title_url"
                          name="title_url"
                          type="file"
                          className="block w-full px-4 py-2 border border-gray-300 rounded"
                          accept="image/*"
                          onChange={(event) => {
                            formik.setFieldValue("title_url", event.currentTarget.files[0]);
                          }}
                        />
                        <ErrorMessage name="title_url" render={renderError} />
                      </div>
                      <div className="space-y-1">
                        <label htmlFor="animation_url">Animation (Lottie)</label>
                        <input
                          id="animation_url"
                          name="animation_url"
                          type="file"
                          className="block w-full px-4 py-2 border border-gray-300 rounded"
                          onChange={(event) => {
                            formik.setFieldValue("animation_url", event.currentTarget.files[0]);
                          }}
                        />
                        <ErrorMessage name="animation_url" render={renderError} />
                      </div>
                      <div className="space-y-1">
                        <label htmlFor="v_animation_url">Vertical Animation (Lottie)</label>
                        <input
                          id="v_animation_url"
                          name="v_animation_url"
                          type="file"
                          className="block w-full px-4 py-2 border border-gray-300 rounded"
                          onChange={(event) => {
                            formik.setFieldValue("v_animation_url", event.currentTarget.files[0]);
                          }}
                        />
                        <ErrorMessage name="v_animation_url" render={renderError} />
                      </div>
                    </div>
                    <div className="my-8">
                      <label className="block w-full">
                        <Field type="checkbox" name="enabled" className="text-red-main mr-2" />
                        <span>Enabled</span>
                      </label>
                    </div>
                    <div className="my-8">
                      <label className="block w-full">
                        <Field type="checkbox" name="allow_join_mid" className="text-red-main mr-2" />
                        <span>Allow Join Mid</span>
                      </label>
                    </div>
                    <hr className="my-4" />
                    <button type="submit" className="px-6 py-2 bg-primary-800 text-white rounded hover:opacity-90 font-cursive">
                      Submit
                    </button>
                  </Form>
                )}
              </Formik>
            </Paper>
          </div>
          <div className="space-y-4">
            <Paper elevation={2} className="p-4 md:p-6">
              <div className="flex items-center justify-between mb-4">
                <h3 className="font-bold text-lg">Rewards</h3>
                <button
                  onClick={() => setIsAddReward(true)}
                  className="p-2.5 bg-primary-800 text-white rounded hover:contrast-75 font-cursive text-xs flex items-center gap-1"
                >
                  <Icon icon="uil:plus" className="w-4 h-4" />
                </button>
              </div>
              <div className="space-y-4">
                {event?.rewards?.map((i) => (
                  <ul key={i.id} className="border p-2 rounded-md relative">
                    <button onClick={() => handleDeleteReward(i.id)} className="absolute top-2 right-2 bg-red-500 rounded p-1.5 text-white">
                      <Icon icon="uil:trash-alt" className="w-4 h-4" />
                    </button>
                    <li>Collection: {i?.collection?.name}</li>
                    <li>Token: {i?.token?.name}</li>
                    <li>Quantity: {i.quantity}</li>
                  </ul>
                ))}
              </div>
            </Paper>

            <Paper elevation={2} className="p-4 md:p-6">
              <div className="flex items-center justify-between mb-4">
                <h3 className="font-bold text-lg">Requirements</h3>
                <button
                  onClick={() => setIsAddReq(true)}
                  className="p-2.5 bg-primary-800 text-white rounded hover:contrast-75 font-cursive text-xs flex items-center gap-1"
                >
                  <Icon icon="uil:plus" className="w-4 h-4" />
                </button>
              </div>
              <div className="space-y-4">
                {event?.requirements?.map((i) => (
                  <ul key={i.id} className="border p-2 rounded-md relative">
                    <button onClick={() => handleDeleteRequirement(i.id)} className="absolute top-2 right-2 bg-red-500 rounded p-1.5 text-white">
                      <Icon icon="uil:trash-alt" className="w-4 h-4" />
                    </button>
                    <li>Collection Type: {i.collection_type}</li>
                    <li>Collection ID: {i.collection_id}</li>
                    <li>Token ID: {i.token_id}</li>
                    <li>Operant: {i.operant}</li>
                    <li>Amount: {i.amount}</li>
                    <li>Token Attribute: {i.token_attribute}</li>
                    <li>List: {i.list}</li>
                    <li>Block Event: {i.block_event ? "true" : "false"}</li>
                  </ul>
                ))}
              </div>
            </Paper>
          </div>
        </div>
      </div>

      <Transition appear show={isAddReq} as={Fragment}>
        <Dialog as="div" className="relative z-30" onClose={() => setIsAddReq(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-bold leading-6 text-gray-900">
                    Add Requirement
                  </Dialog.Title>
                  <div className="mt-4 border-t pt-4">
                    <Formik
                      enableReinitialize
                      initialValues={{
                        event_id: event?.id,
                        collection_id: "",
                        collection_type: "",
                        operant: "",
                        token_id: "",
                        amount: "",
                        token_attribute: "",
                        list: "",
                        block_event: "",
                      }}
                      validationSchema={validationSchemaRequirement}
                      onSubmit={(values) => {
                        handleSubmitRequirement(values);
                      }}
                    >
                      {(formik) => (
                        <Form className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          <div className="space-y-1">
                            <label htmlFor="collection_id">Collection ID *</label>
                            <Field name="collection_id" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                            <ErrorMessage name="collection_id" render={renderError} />
                          </div>
                          <div className="space-y-1">
                            <label htmlFor="collection_type">Collection Type *</label>
                            <Field name="collection_type" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                            <ErrorMessage name="collection_type" render={renderError} />
                          </div>
                          <div className="space-y-1">
                            <label htmlFor="operant">Operant *</label>
                            <Field as="select" name="operant" className="block w-full px-4 py-2 border border-gray-300 rounded">
                              <option value="">-- Select --</option>
                              <option value="eq">=</option>
                              <option value="gt">{`>`}</option>
                              <option value="gte">{`>=`}</option>
                              <option value="lt">{`<`}</option>
                              <option value="lte">{`<=`}</option>
                            </Field>
                            <ErrorMessage name="operant" render={renderError} />
                          </div>
                          <div className="space-y-1">
                            <label htmlFor="token_id">Token ID *</label>
                            <Field name="token_id" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                            <ErrorMessage name="token_id" render={renderError} />
                          </div>
                          <div className="space-y-1">
                            <label htmlFor="amount">Amount *</label>
                            <Field name="amount" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                            <ErrorMessage name="amount" render={renderError} />
                          </div>
                          <div className="space-y-1">
                            <label htmlFor="token_attribute">Token Attribute</label>
                            <Field name="token_attribute" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                            <ErrorMessage name="token_attribute" render={renderError} />
                          </div>
                          <div className="space-y-1">
                            <label htmlFor="list">List</label>
                            <Field name="list" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                            <ErrorMessage name="list" render={renderError} />
                          </div>
                          <div className="space-y-1">
                            <label className="flex items-center gap-2">
                              <Field type="checkbox" name="block_event" />
                              Block Event
                            </label>
                            <ErrorMessage name="block_event" render={renderError} />
                          </div>
                          <button type="submit" className="px-6 py-2 bg-primary-800 text-white rounded hover:opacity-90 font-cursive">
                            Submit
                          </button>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition appear show={isAddReward} as={Fragment}>
        <Dialog as="div" className="relative z-30" onClose={() => setIsAddReward(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-bold leading-6 text-gray-900">
                    Add Reward
                  </Dialog.Title>
                  <div className="mt-4 border-t pt-4">
                    <Formik
                      enableReinitialize
                      initialValues={{
                        collection_id: "",
                        token_id: "",
                        quantity: "",
                      }}
                      validationSchema={validationSchemaReward}
                      onSubmit={(values) => {
                        handleSubmitReward(values);
                      }}
                    >
                      {(formik) => (
                        <Form className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          <div className="space-y-1">
                            <label htmlFor="collection_id">Collection ID *</label>
                            <Field name="collection_id" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                            <ErrorMessage name="collection_id" render={renderError} />
                          </div>
                          <div className="space-y-1">
                            <label htmlFor="token_id">Token ID *</label>
                            <Field name="token_id" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                            <ErrorMessage name="token_id" render={renderError} />
                          </div>
                          <div className="space-y-1">
                            <label htmlFor="quantity">Quantity *</label>
                            <Field name="quantity" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                            <ErrorMessage name="quantity" render={renderError} />
                          </div>
                          <div></div>
                          <button type="submit" className="px-6 py-2 bg-primary-800 text-white rounded hover:opacity-90 font-cursive">
                            Submit
                          </button>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Toaster />
    </LayoutBase>
  );
}

export default AdminEditEvent;

import { ethers } from "ethers";
import { getAccount, writeContract, waitForTransaction } from "wagmi/actions";
import { Interface, FormatTypes } from "@ethersproject/abi";
import { default as KGearsABI } from "./kgears.json";

const KGEARS_CONTRACT_ADDRESS = process.env.REACT_APP_KGEARS_CONTRACT_ADDRESS;
const khugaABI = new Interface(KGearsABI).format(FormatTypes.full);
const RPC_PROVIDER = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_KHUGA_JSON_RPC_NODE);
const KGearsContractReader = new ethers.Contract(KGEARS_CONTRACT_ADDRESS, KGearsABI, RPC_PROVIDER);

export const claimKGearsReward = async (destAddress, tokenId, claimAmount, maxAmount, signature) => {
  if (!window.ethereum) throw "No Injected Web3 Provider Found";
  const ethereum = window.ethereum;
  const accounts = await ethereum.request({
    method: "eth_requestAccounts",
  });

  const provider = new ethers.providers.Web3Provider(ethereum);
  const walletAddress = accounts[0];
  const signer = provider.getSigner(walletAddress);
  console.log(walletAddress);

  const kgearsContract = new ethers.Contract(KGEARS_CONTRACT_ADDRESS, KGearsABI, signer);
  const claimReward = await kgearsContract.populateTransaction.claimReward(destAddress, tokenId, claimAmount, maxAmount, signature);
  claimReward.chainId = process.env.REACT_APP_KHUGA_NFT_CHAIN_ID;
  claimReward.gasLimit = 155000;

  const claimRewardTx = await signer.sendTransaction(claimReward).catch((e) => {
    throw e.error.data.data.message;
  });

  const txReceipt = await claimRewardTx.wait();
  if (txReceipt.status === 0)
    throw new Error("Approve transaction failed");

  return {
    txHash: claimRewardTx.hash,
    receiptStatus: txReceipt.status
  }
};

export const authorizedMint = async (tokenId, amount, maxAmount, price, signature) => {
  const account = getAccount();
  if (!account.isConnected) throw "Not Connected to Wallet";
  console.log(`Using account: ${account.address}`);

  const txHash = await writeContract({
    address: KGEARS_CONTRACT_ADDRESS,
    abi: KGearsABI,
    functionName: "authorizedMint",
    args: [tokenId, amount, maxAmount, signature],
    value: ethers.utils.parseEther(price.toString()),
    chaindId: process.env.REACT_APP_KHUGA_NFT_CHAIN_ID,
    gas: 155_000,
  }).catch((e) => {
    console.log(`Contract write error with: ${e.message}`);
    // console.log(e);
    throw "Contract Write Failed";
  });
  // console.log(txHash);

  if (!txHash) return;

  const txReceipt = await waitForTransaction({
    hash: txHash.hash,
  }).catch((e) => {
    console.log(`Tx failed with: ${e.message}`);
    throw "Transaction Failed";
  });

  return {
    txHash: txHash.hash,
    receipt: txReceipt,
  };
};

export const burn = async (address, tokenId, amount) => {
  const account = getAccount();
  if (!account.isConnected) throw "Not Connected to Wallet";
  console.log(`Using account: ${account.address}`);

  const txHash = await writeContract({
    address: KGEARS_CONTRACT_ADDRESS,
    abi: KGearsABI,
    functionName: "burn",
    args: [address, tokenId, amount],
    chaindId: process.env.REACT_APP_KHUGA_NFT_CHAIN_ID,
  }).catch((e) => {
    console.log(`Contract write error with: ${e.message}`);
    // console.log(e);
    throw "Contract Write Failed";
  });
  // console.log(txHash);

  if (!txHash) return;

  const txReceipt = await waitForTransaction({
    hash: txHash.hash,
  }).catch((e) => {
    console.log(`Tx failed with: ${e.message}`);
    throw "Transaction Failed";
  });

  return {
    txHash: txHash.hash,
    receipt: txReceipt,
  };
};

export const isClaimed = async (address, tokenId) => {
  return await KGearsContractReader.isClaimed(address, tokenId);
};

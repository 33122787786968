import moment from "moment";
import { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useAuthenticatedHttpClient } from "../../utils/authenticated-api-call";
import { filter, find } from "lodash";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Icon } from "@iconify/react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";

export default function SelectCollection({ event }) {
  const navigate = useNavigate();
  const metamaskAddress = localStorage.getItem("metamask_address");
  const { internalAPIClient } = useAuthenticatedHttpClient();
  const [assetCollections, setAssetCollections] = useState([]);
  const [selectedCollections, setSelectedCollections] = useState([]);
  const [idleCollections, setIdleCollections] = useState([]);
  const [isStarted, setIsStarted] = useState(false);

  useEffect(async () => {
    const getTime = moment.utc(event?.start_time).diff(moment.utc(moment.utc().local()));
    setIsStarted(getTime < 0);

    await internalAPIClient
      .get(`/missions/${event?.id}/assets/${metamaskAddress}`)
      .then((res) => {
        setAssetCollections(res?.data?.data);
        setIdleCollections(res?.data?.data?.filter((i) => i.join_status === "IDLE"));
        setSelectedCollections(res?.data?.data?.filter((i) => i.join_status === "JOINED"));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleSelectCollection = (id) => {
    if (isStarted && !event?.allow_join_mid) {
      return;
    }

    const selectedData = find(assetCollections, (o) => o.metadata.id == id);
    if (selectedData.ineligible_status || selectedData.listing_status === "LISTED") {
      return;
    }

    const getSelected = selectedCollections ?? [];

    let newSelected = [...getSelected, selectedData];
    setSelectedCollections(newSelected);
    setIdleCollections(filter(idleCollections, (o) => o.metadata.id != id));
  };

  const handleUnselectCollection = (id) => {
    if (isStarted && !event?.allow_join_mid) {
      return;
    }

    const getIdle = idleCollections ?? [];

    let newIdle = [...getIdle, find(assetCollections, (o) => o.metadata.id == id)];
    setIdleCollections(newIdle);
    setSelectedCollections(filter(selectedCollections, (o) => o.metadata.id != id));
  };

  const handleSaveData = async () => {
    if (selectedCollections.length < 1) {
      return toast.error("Please select at least 1 collection");
    }

    let assetData = [];
    selectedCollections.map((i) =>
      assetData.push({
        event_id: event?.id,
        owner: metamaskAddress,
        token_id: i?.metadata.id,
        collection_id: "0",
        additional_attribute: "",
      })
    );

    await internalAPIClient
      .post(`/missions/${event?.id}/assets/${metamaskAddress}`, assetData)
      .then((res) => {
        if (res?.data?.status == "success") {
          toast.success("Success save data");
          setTimeout(() => {
            return navigate(`${process.env.PUBLIC_URL}/miawwhalla/event`);
          }, 1000);
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedCollections(assetCollections.filter((o) => !o.ineligible_status).filter((o) => o.listing_status !== "LISTED"));
      setIdleCollections(assetCollections.filter((o) => o.ineligible_status || o.listing_status === "LISTED"));
    } else {
      setSelectedCollections([]);
      setIdleCollections(assetCollections);
    }
  };

  return (
    <div className="w-full h-full px-4">
      <div className="hidden md:grid grid-cols-2 gap-4 h-16 items-center mt-6">
        <div className="flex items-center justify-between">
          <h2 className="font-cursive text-xl">My Khuga Collection ({idleCollections.length})</h2>
          {!isStarted && (
            <div>
              <label className="flex items-center gap-2">
                <p>Select all</p>
                <input type="checkbox" className="text-primary-700" onClick={handleSelectAll} />
              </label>
            </div>
          )}
        </div>
        <div>
          <h2 className="font-cursive text-xl">
            {isStarted ? (
              <span>
                My Squad ({selectedCollections.filter((i) => i.disqualified_status !== 1).length}/{selectedCollections.length})
              </span>
            ) : (
              <span>Squad Setup ({selectedCollections.length})</span>
            )}
          </h2>
        </div>
      </div>
      <div className="md:grid md:grid-cols-2 gap-4 h-[calc(100%_-_6rem)] md:h-[calc(100%_-_9.5rem)] mt-8 md:mt-0">
        <div className="h-16 md:hidden flex items-center justify-between pt-6">
          <h2 className="font-cursive text-xl">My Khuga Collection ({idleCollections.length})</h2>
          {!isStarted && (
            <div>
              <label className="flex items-center gap-2">
                <p>Select all</p>
                <input type="checkbox" className="text-primary-700" onClick={handleSelectAll} />
              </label>
            </div>
          )}
        </div>
        <div className="h-[calc(50%_-_4rem)] md:h-full rounded-lg py-4 overflow-auto scrollbar-thin scrollbar-thumb-[#ADADF0] scrollbar-track-[#7575A0] scrollbar-rounded-lg">
          <div className="grid grid-cols-5 gap-4 mr-4">
            {idleCollections.map((i) => (
              <div
                className={`text-center cursor-pointer group w-full relative group overflow-hidden ${(i.ineligible_status || i.listing_status === "LISTED") && "grayscale"}`}
                onClick={() => handleSelectCollection(i?.metadata.id)}
                key={i.id}
              >
                <div className="w-full aspect-square overflow-hidden rounded-xl group-hover:shadow-xl transition-all">
                  <div className="w-full aspect-square rounded-xl mb-1 group-hover:scale-110 transition-all duration-300">
                    <LazyLoadImage src={`https://khuga.io/khuga-preview/${i?.metadata.id}.webp`} alt="?" effect="blur" className="w-full" />
                  </div>
                </div>
                <div className="flex justify-center items-center mt-1">
                  <h3 className="text-xs text-black/80">
                    <span>{i?.metadata.name}</span>
                  </h3>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="md:hidden h-16 flex items-center">
          <h2 className="font-cursive text-xl">
            {isStarted ? (
              <span>
                My Squad ({selectedCollections.filter((i) => i.disqualified_status !== 1).length}/{selectedCollections.length})
              </span>
            ) : (
              <span>Squad Setup ({selectedCollections.length})</span>
            )}
          </h2>
        </div>
        <div className="h-[calc(50%_-_4rem)] md:h-full bg-white rounded-lg p-4 overflow-auto scrollbar-thin scrollbar-thumb-[#ADADF0] scrollbar-track-[#7575A0] scrollbar-rounded-lg">
          <div className="grid grid-cols-5 gap-4 mr-4">
            {selectedCollections.map((i) => (
              <div
                className="text-center cursor-pointer group w-full relative group overflow-hidden"
                onClick={() => handleUnselectCollection(i?.metadata.id)}
                key={i.id}
              >
                {i.disqualified_status === 1 && (
                  <div className="w-full aspect-square absolute z-10 inset-0 backdrop-grayscale flex items-center justify-center">
                    <Icon icon="ph:x-light" className="w-full h-full text-red-500" />
                  </div>
                )}
                <div className="w-full aspect-square overflow-hidden rounded-xl group-hover:shadow-xl transition-all">
                  <div className="w-full aspect-square rounded-xl mb-1 group-hover:scale-110 transition-all duration-300">
                    <LazyLoadImage src={`https://khuga.io/khuga-preview/${i?.metadata.id}.webp`} alt="?" effect="blur" className="w-full" />
                  </div>
                </div>
                <div className="flex justify-center items-center mt-1">
                  <h3 className="text-xs text-black/80">
                    <span>{i?.metadata.name}</span>
                  </h3>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="h-16 flex items-center justify-end">
        {isStarted && !event?.allow_join_mid ? (
          <button className="bg-[#439583] text-white px-8 p-2 rounded-md font-cursive block cursor-not-allowed transition-all grayscale">
            <span>Save My Squad</span>
          </button>
        ) : (
          <button
            onClick={() => handleSaveData()}
            className="bg-[#439583] text-white px-8 p-2 rounded-md font-cursive block hover:contrast-125 hover:scale-[1.02] active:scale-[.98] transition-all"
          >
            <span>Save My Squad</span>
          </button>
        )}
      </div>
    </div>
  );
}

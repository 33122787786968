import { Icon } from "@iconify/react";
import React from "react";
import AdminDataTransaction from "./pages/Admin/Transaction/AdminDataTransaction";
import AdminDataWhitelist from "./pages/Admin/AdminDataWhitelist";
import Checkout from "./pages/Checkout";
import Collections from "./pages/Collections";
import Dashboard from "./pages/Dashboard";
import DigitalGoodies from "./pages/DigitalGoodies";
import Merchandise from "./pages/Merchandise";
import MiawwhallaBattle from "./pages/Miawwhalla/Battle";
import MiawwhallaClaim from "./pages/Miawwhalla/Claim";
import MiawwhallaCollections from "./pages/Miawwhalla/Collections";
import MiawwhallaDashboard from "./pages/Miawwhalla/Dashboard";
import MiawwhallaEvent from "./pages/Miawwhalla/Event";
import MiawwhallaEventDetail from "./pages/Miawwhalla/EventDetail";
import MiawwhallaProfile from "./pages/Miawwhalla/Profile";
import Profile from "./pages/Profile";
import TransactionConfirm from "./pages/TransactionConfirm";
import Transactions from "./pages/Transactions";
import AdminCreateEvent from "./pages/Admin/Event/AdminCreateEvent";
import AdminListEvent from "./pages/Admin/Event/AdminListEvent";
import AdminEditEvent from "./pages/Admin/Event/AdminEditEvent";
import AdminParticipantEvent from "./pages/Admin/Event/AdminParticipantEvent";
import MiawwhallaVote from "./pages/Miawwhalla/Vote";
import MiawwhallaVoteCandidate from "./pages/Miawwhalla/VoteCandidate";
import AdminTransactionDetail from "./pages/Admin/Transaction/AdminTransactionDetail";
import ListProduct from "./pages/Admin/Marketplace/ListProduct";
import CreateProduct from "./pages/Admin/Marketplace/CreateProduct";
import EditProduct from "./pages/Admin/Marketplace/EditProduct";
import AdminStoryEvent from "./pages/Admin/Event/AdminStoryEvent";

const routes = [
  {
    type: "menu",
    name: "Home",
    key: "home",
    route: "/home",
    roles: ["user", "holder", "superadmin"],
    component: <Dashboard />,
    icon: <Icon icon="heroicons-outline:home" className="w-6 h-6" />,
  },
  // {
  //   type: "menu",
  //   name: "Collections",
  //   key: "collections",
  //   route: "/collections",
  //   roles: ["user", "holder", "superadmin"],
  //   component: <Collections />,
  //   icon: <Icon icon="heroicons-outline:view-grid" className="w-6 h-6" />,
  // },
  // {
  //   type: "menu",
  //   name: "Merchandise",
  //   key: "merchandise",
  //   route: "/merchandise",
  //   roles: ["user", "holder", "superadmin"],
  //   component: <Merchandise />,
  //   icon: <Icon icon="heroicons-outline:shopping-bag" className="w-6 h-6" />,
  //   submenu: [
  //     {
  //       type: "submenu",
  //       name: "Order",
  //       key: "order",
  //       route: "/merchandise",
  //       roles: ["user", "holder", "superadmin"],
  //     },
  //     {
  //       type: "submenu",
  //       name: "Transactions",
  //       key: "transactions",
  //       route: "/transactions",
  //       roles: ["user", "holder", "superadmin"],
  //     },
  //   ],
  // },
  {
    type: "menu",
    name: "Marketplace",
    key: "marketplace",
    route: "/admin/marketplace",
    roles: ["superadmin"],
    icon: <Icon icon="heroicons-outline:shopping-bag" className="w-6 h-6" />,
    submenu: [
      {
        type: "submenu",
        name: "Products",
        key: "marketplace-product",
        route: "/admin/marketplace/product",
        roles: ["superadmin"],
      },
      {
        type: "submenu",
        name: "Transactions",
        key: "marketplace-transaction",
        route: "/admin/marketplace/transaction",
        roles: ["superadmin"],
      },
    ],
  },
  {
    type: "route",
    name: "List Marketplace Product",
    key: "admin-marketplace-product",
    route: "/admin/marketplace/product",
    roles: ["superadmin"],
    component: <ListProduct />,
  },
  {
    type: "route",
    name: "Create Marketplace Product",
    key: "admin-marketplace-product-create",
    route: "/admin/marketplace/product/create",
    roles: ["superadmin"],
    component: <CreateProduct />,
  },
  {
    type: "route",
    name: "Edit Marketplace Product",
    key: "admin-marketplace-product-edit",
    route: "/admin/marketplace/product/:id/edit",
    roles: ["superadmin"],
    component: <EditProduct />,
  },
  // {
  //   type: "menu",
  //   name: "Digital Goodies",
  //   key: "digital-goodies",
  //   route: "/digital-goodies",
  //   roles: ["user", "holder", "superadmin"],
  //   component: <DigitalGoodies />,
  //   icon: <Icon icon="heroicons-outline:cube" className="w-6 h-6" />,
  // },
  {
    type: "menu",
    name: "Data Event",
    key: "admin-event",
    route: "/admin/event",
    roles: ["superadmin"],
    component: <AdminListEvent />,
    icon: <Icon icon="heroicons-outline:clipboard-list" className="w-6 h-6" />,
  },
  {
    type: "route",
    name: "Create Event",
    key: "admin-event-create",
    route: "/admin/event/create",
    roles: ["superadmin"],
    component: <AdminCreateEvent />,
    icon: <Icon icon="heroicons-outline:clipboard-list" className="w-6 h-6" />,
  },
  {
    type: "route",
    name: "Edit Event",
    key: "admin-event-edit",
    route: "/admin/event/:id/edit",
    roles: ["superadmin"],
    component: <AdminEditEvent />,
    icon: <Icon icon="heroicons-outline:clipboard-list" className="w-6 h-6" />,
  },
  {
    type: "route",
    name: "Story Event",
    key: "admin-event-story",
    route: "/admin/event/:id/story",
    roles: ["superadmin"],
    component: <AdminStoryEvent />,
    icon: <Icon icon="heroicons-outline:clipboard-list" className="w-6 h-6" />,
  },
  {
    type: "route",
    name: "Event Participant",
    key: "admin-event-participant",
    route: "/admin/event/:id/participant",
    roles: ["superadmin"],
    component: <AdminParticipantEvent />,
    icon: <Icon icon="heroicons-outline:clipboard-list" className="w-6 h-6" />,
  },
  {
    type: "menu",
    name: "Data Transaction",
    key: "admin-transaction",
    route: "/admin/transaction",
    roles: ["superadmin"],
    component: <AdminDataTransaction />,
    icon: <Icon icon="heroicons-outline:clipboard-list" className="w-6 h-6" />,
  },
  {
    type: "route",
    name: "Data Transaction",
    key: "admin-transaction-detail",
    route: "/admin/transaction/:id",
    roles: ["superadmin"],
    component: <AdminTransactionDetail />,
    icon: <Icon icon="heroicons-outline:clipboard-list" className="w-6 h-6" />,
  },
  // {
  //   type: "menu",
  //   name: "Data Whitelist",
  //   key: "admin-whitelist",
  //   route: "/admin/whitelist",
  //   roles: ["superadmin"],
  //   component: <AdminDataWhitelist />,
  //   icon: <Icon icon="heroicons-outline:clipboard-list" className="w-6 h-6" />,
  // },
  {
    type: "route",
    name: "Miawhalla",
    key: "miawwhalla",
    route: "/miawwhalla",
    roles: ["user", "holder", "superadmin"],
    component: <MiawwhallaDashboard />,
    icon: <Icon icon="heroicons-outline:home" className="w-6 h-6" />,
  },
  {
    type: "route",
    name: "Miawhalla Profile",
    key: "miawwhalla-profile",
    route: "/miawwhalla/profile",
    roles: ["user", "holder", "superadmin"],
    component: <MiawwhallaProfile />,
    icon: <Icon icon="heroicons-outline:home" className="w-6 h-6" />,
  },
  {
    type: "route",
    name: "Miawhalla Event",
    key: "miawwhalla-event",
    route: "/miawwhalla/event",
    roles: ["user", "holder", "superadmin"],
    component: <MiawwhallaEvent />,
    icon: <Icon icon="heroicons-outline:home" className="w-6 h-6" />,
  },
  {
    type: "route",
    name: "Miawhalla Event",
    key: "miawwhalla-event-detail",
    route: "/miawwhalla/event/:id",
    roles: ["user", "holder", "superadmin"],
    component: <MiawwhallaEventDetail />,
  },
  {
    type: "route",
    name: "Miawhalla Battle",
    key: "miawwhalla-battle",
    route: "/miawwhalla/event/:id/battle",
    roles: ["user", "holder", "superadmin"],
    component: <MiawwhallaBattle />,
  },
  {
    type: "route",
    name: "Miawhalla Vote",
    key: "miawwhalla-vote",
    route: "/miawwhalla/event/:id/vote",
    roles: ["user", "holder", "superadmin"],
    component: <MiawwhallaVote />,
  },
  {
    type: "route",
    key: "miawwhalla-vote-candidate",
    route: "/miawwhalla/event/:id/vote-candidate",
    roles: ["user", "holder", "superadmin"],
    component: <MiawwhallaVoteCandidate />,
  },
  {
    type: "route",
    name: "Miawhalla Claim",
    key: "miawwhalla-claim",
    route: "/miawwhalla/event/:id/claim",
    roles: ["user", "holder", "superadmin"],
    component: <MiawwhallaClaim />,
  },
  // {
  //   type: "route",
  //   name: "Miawhalla Collection",
  //   key: "miawwhalla-collection",
  //   route: "/miawwhalla/collection",
  //   roles: ["user", "holder", "superadmin"],
  //   component: <MiawwhallaCollections />,
  //   icon: <Icon icon="heroicons-outline:home" className="w-6 h-6" />,
  // },
  {
    type: "route",
    name: "Transaction",
    key: "transaction",
    route: "/transactions",
    roles: ["user", "holder", "superadmin"],
    component: <Transactions />,
    icon: <Icon icon="heroicons-outline:clipboard-list" className="w-6 h-6" />,
  },
  {
    type: "route",
    name: "Confirm Transaction",
    key: "confirm-transaction",
    route: "/transactions/:id",
    roles: ["user", "holder", "superadmin"],
    component: <TransactionConfirm />,
  },
  {
    type: "route",
    name: "Checkout",
    key: "checkout",
    route: "/checkout",
    roles: ["user", "holder", "superadmin"],
    component: <Checkout />,
  },
  {
    type: "route",
    name: "Profile",
    key: "profile",
    route: "/profile",
    roles: ["user", "holder", "superadmin"],
    component: <Profile />,
  },
];

export default routes;
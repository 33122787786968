import { Icon } from "@iconify/react";
import { Link, useNavigate, useParams } from "react-router-dom";
import LayoutBase from "../../../layouts/LayoutBase";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as yup from "yup";
import toast, { Toaster } from "react-hot-toast";
import { Fragment, useEffect, useState } from "react";
import { useAuthenticatedHttpClient } from "../../../utils/authenticated-api-call";
import LoadingSpinner from "../../../partials/LoadingSpinner";
import { Dialog, Transition } from "@headlessui/react";
import { confirmAlert } from "react-confirm-alert";

function AdminStoryEvent() {
  const params = useParams();
  const navigate = useNavigate();
  const { internalAPIClient } = useAuthenticatedHttpClient();
  const [event, setEvent] = useState();
  const [isAddStory, setIsAddStory] = useState(false);
  const [editStory, setEditStory] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = yup.object().shape({
    story: yup.string().required(),
    active_mode: yup.string().required(),
    active_value: yup.string().required(),
    sort: yup.string().required(),
    // animation_url: yup.string().required(),
    // h_animation_url: yup.string().required(),
    // v_animation_url: yup.string().required(),
  });

  const renderError = (message) => <p className="text-sm italic text-red-600">{message}</p>;

  useEffect(() => {
    getEvent();
  }, []);

  const getEvent = async () => {
    await internalAPIClient
      .get(`/cms/missions/${params?.id}`)
      .then((res) => {
        setEvent(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error occured");
      });
  };

  const handleSubmitStory = async (values) => {
    setIsLoading(true);

    var formData = new FormData();
    for (var key in values) {
      formData.append(key, values[key]);
    }

    await internalAPIClient
      .post(`/cms/missions/${params?.id}/stories`, formData)
      .then((res) => {
        if (res?.data?.status === "success") {
          toast.success("Success create event story", { position: "top-right" });
          setTimeout(() => {
            // return navigate(`${process.env.PUBLIC_URL}/admin/event/${event.id}/story`);
            window.location.reload();
          }, 1500);
        } else {
          console.log(res);
          toast.error("Failed to create event story");
        }
      })
      .catch(async (e) => {
        console.log(e);
        toast.error("Failed to create event story");
      });

    setIsLoading(false);
  };

  const handleUpdateStory = async (values) => {
    setIsLoading(true);

    var formData = new FormData();
    for (var key in values) {
      formData.append(key, values[key]);
    }

    await internalAPIClient
      .put(`/cms/missions/${params?.id}/stories/${editStory?.id}`, formData)
      .then((res) => {
        if (res?.data?.status === "success") {
          toast.success("Success update event story", { position: "top-right" });
          setTimeout(() => {
            // return navigate(`${process.env.PUBLIC_URL}/admin/event/${event.id}/story`);
            window.location.reload();
          }, 1500);
        } else {
          console.log(res);
          toast.error("Failed to update event story");
        }
      })
      .catch(async (e) => {
        console.log(e);
        toast.error("Failed to update event story");
      });

    setIsLoading(false);
  };

  const handleEditStory = (story) => {
    setEditStory(story);
  };

  const handleDeleteStory = (id) => {
    confirmAlert({
      title: "Are you sure?",
      message: "The data will be permanently deleted",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            setIsLoading(true);
            await internalAPIClient
              .delete(`/cms/missions/${params?.id}/stories/${id}`)
              .then((res) => {
                if (res?.data?.status === "success") {
                  toast.success("Success!");
                  getEvent();
                } else {
                  toast.error("Error occured!");
                }
                setIsLoading(false);
              })
              .catch((err) => {
                console.log(err);
                toast.error("Error occured!");
              });
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  return (
    <LayoutBase>
      {isLoading && <LoadingSpinner />}

      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        <div className="flex items-center justify-between">
          <h1 className="font-bold text-2xl mb-4 font-cursive">Event Story</h1>
          <div className="flex items-center gap-2">
            <Link
              to="/admin/event"
              className="px-5 py-2.5 bg-primary-800 text-white rounded hover:contrast-75 font-cursive text-xs mb-4 flex items-center gap-1"
            >
              <Icon icon="uil:arrow-left" className="w-4 h-4" />
              <span>Back</span>
            </Link>
            <button
              onClick={() => setIsAddStory(true)}
              className="px-5 py-2.5 bg-primary-800 text-white rounded hover:contrast-75 font-cursive text-xs mb-4 flex items-center gap-1"
            >
              <Icon icon="uil:plus" className="w-4 h-4" />
              <span>New</span>
            </button>
          </div>
        </div>

        {event?.stories?.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
            {event?.stories?.map((i) => (
              <div key={i.id} className="w-full bg-white p-4 rounded-md border shadow relative">
                <div className="absolute right-3 top-3 flex items-center gap-2">
                  <button onClick={() => handleEditStory(i)} className="p-2 rounded-md bg-primary-500 text-white">
                    <Icon icon="heroicons-outline:pencil-alt" />
                  </button>
                  <button onClick={() => handleDeleteStory(i.id)} className="p-2 rounded-md bg-red-500 text-white">
                    <Icon icon="heroicons-outline:trash" />
                  </button>
                </div>
                <div className="uppercase bg-primary-50 inline-block px-2 py-1 text-xs font-bold text-primary-800 rounded">
                  {i.active_mode}
                  {i.active_value}
                </div>
                <img src={i.animation_url} alt="" className="w-full rounded-md mb-2" />
                <p>{i.story}</p>
              </div>
            ))}
          </div>
        ) : (
          <p>Stories not found</p>
        )}
      </div>

      <Transition appear show={isAddStory} as={Fragment}>
        <Dialog as="div" className="relative z-30" onClose={() => setIsAddStory(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-bold leading-6 text-gray-900">
                    Add Story
                  </Dialog.Title>
                  <div className="mt-4 border-t pt-4">
                    <Formik
                      enableReinitialize
                      initialValues={{
                        event_id: event?.id,
                        story: "",
                        active_mode: "w",
                        active_value: "0",
                        sort: event?.stories.length + 1,
                        animation_url: "",
                        h_animation_url: "",
                        v_animation_url: "",
                      }}
                      validationSchema={validationSchema}
                      onSubmit={(values) => {
                        handleSubmitStory(values);
                      }}
                    >
                      {(formik) => (
                        <Form>
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                            <div className="space-y-1 col-span-2">
                              <label htmlFor="story">Story *</label>
                              <Field name="story" as="textarea" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                              <ErrorMessage name="story" render={renderError} />
                            </div>
                            <div className="space-y-1">
                              <label htmlFor="active_mode">Active Mode *</label>
                              <Field as="select" name="active_mode" className="block w-full px-4 py-2 border border-gray-300 rounded">
                                <option value="w">Week</option>
                              </Field>
                              <ErrorMessage name="story" render={renderError} />
                            </div>
                            <div className="space-y-1">
                              <label htmlFor="active_value">Active Value *</label>
                              <Field name="active_value" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                              <ErrorMessage name="active_value" render={renderError} />
                            </div>
                            <div className="space-y-1">
                              <label htmlFor="sort">Sort *</label>
                              <Field name="sort" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                              <ErrorMessage name="sort" render={renderError} />
                            </div>
                            <div className="space-y-1">
                              <label htmlFor="animation_url">Animation URL *</label>
                              <input
                                id="animation_url"
                                name="animation_url"
                                type="file"
                                className="block w-full px-4 py-2 border border-gray-300 rounded"
                                accept="image/*"
                                onChange={(event) => {
                                  formik.setFieldValue("animation_url", event.currentTarget.files[0]);
                                }}
                              />
                              <ErrorMessage name="animation_url" render={renderError} />
                            </div>
                            <div className="space-y-1">
                              <label htmlFor="h_animation_url">Hor. Animation URL *</label>
                              <input
                                id="h_animation_url"
                                name="h_animation_url"
                                type="file"
                                className="block w-full px-4 py-2 border border-gray-300 rounded"
                                accept="image/*"
                                onChange={(event) => {
                                  formik.setFieldValue("h_animation_url", event.currentTarget.files[0]);
                                }}
                              />
                              <ErrorMessage name="h_animation_url" render={renderError} />
                            </div>
                            <div className="space-y-1">
                              <label htmlFor="v_animation_url">Ver. Animation URL *</label>
                              <input
                                id="v_animation_url"
                                name="v_animation_url"
                                type="file"
                                className="block w-full px-4 py-2 border border-gray-300 rounded"
                                accept="image/*"
                                onChange={(event) => {
                                  formik.setFieldValue("v_animation_url", event.currentTarget.files[0]);
                                }}
                              />
                              <ErrorMessage name="v_animation_url" render={renderError} />
                            </div>
                            <label className="block w-full">
                              <Field type="checkbox" name="story_overlay" className="mr-2" />
                              <span>Story Overlay</span>
                            </label>
                          </div>
                          <button type="submit" className="px-6 py-2 bg-primary-800 text-white rounded hover:opacity-90 font-cursive">
                            Submit
                          </button>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition appear show={editStory !== false} as={Fragment}>
        <Dialog as="div" className="relative z-30" onClose={() => setEditStory(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-bold leading-6 text-gray-900">
                    Edit Story
                  </Dialog.Title>
                  <div className="mt-4 border-t pt-4">
                    <Formik
                      enableReinitialize
                      initialValues={{
                        event_id: event?.id,
                        story: editStory?.story,
                        story_overlay: editStory?.story_overlay,
                        active_mode: editStory?.active_mode,
                        active_value: editStory?.active_value,
                        sort: editStory?.sort,
                        animation_url: "",
                        h_animation_url: "",
                        v_animation_url: "",
                      }}
                      validationSchema={validationSchema}
                      onSubmit={(values) => {
                        handleUpdateStory(values);
                      }}
                    >
                      {(formik) => (
                        <Form>
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                            <div className="space-y-1 col-span-2">
                              <label htmlFor="story">Story *</label>
                              <Field name="story" as="textarea" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                              <ErrorMessage name="story" render={renderError} />
                            </div>
                            <div className="space-y-1">
                              <label htmlFor="active_mode">Active Mode *</label>
                              <Field as="select" name="active_mode" className="block w-full px-4 py-2 border border-gray-300 rounded">
                                <option value="w">Week</option>
                              </Field>
                              <ErrorMessage name="story" render={renderError} />
                            </div>
                            <div className="space-y-1">
                              <label htmlFor="active_value">Active Value *</label>
                              <Field name="active_value" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                              <ErrorMessage name="active_value" render={renderError} />
                            </div>
                            <div className="space-y-1">
                              <label htmlFor="sort">Sort *</label>
                              <Field name="sort" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                              <ErrorMessage name="sort" render={renderError} />
                            </div>
                            <div className="space-y-1">
                              <label htmlFor="animation_url">Animation URL *</label>
                              <input
                                id="animation_url"
                                name="animation_url"
                                type="file"
                                className="block w-full px-4 py-2 border border-gray-300 rounded"
                                accept="image/*"
                                onChange={(event) => {
                                  formik.setFieldValue("animation_url", event.currentTarget.files[0]);
                                }}
                              />
                              <ErrorMessage name="animation_url" render={renderError} />
                            </div>
                            <div className="space-y-1">
                              <label htmlFor="h_animation_url">Hor. Animation URL *</label>
                              <input
                                id="h_animation_url"
                                name="h_animation_url"
                                type="file"
                                className="block w-full px-4 py-2 border border-gray-300 rounded"
                                accept="image/*"
                                onChange={(event) => {
                                  formik.setFieldValue("h_animation_url", event.currentTarget.files[0]);
                                }}
                              />
                              <ErrorMessage name="h_animation_url" render={renderError} />
                            </div>
                            <div className="space-y-1">
                              <label htmlFor="v_animation_url">Ver. Animation URL *</label>
                              <input
                                id="v_animation_url"
                                name="v_animation_url"
                                type="file"
                                className="block w-full px-4 py-2 border border-gray-300 rounded"
                                accept="image/*"
                                onChange={(event) => {
                                  formik.setFieldValue("v_animation_url", event.currentTarget.files[0]);
                                }}
                              />
                              <ErrorMessage name="v_animation_url" render={renderError} />
                            </div>
                            <label className="block w-full">
                              <Field type="checkbox" name="story_overlay" className="mr-2" />
                              <span>Story Overlay</span>
                            </label>
                          </div>
                          <button type="submit" className="px-6 py-2 bg-primary-800 text-white rounded hover:opacity-90 font-cursive">
                            Submit
                          </button>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Toaster />
    </LayoutBase>
  );
}

export default AdminStoryEvent;

import { useConnect, useAccount, useSignMessage } from "wagmi";

const _signMessage = async (signMessage, pubkey, nonce) => {
  let signature;

  // const {connect, isSuccess, isError, error, data, connectors} = useConnect();
  // console.log(connectors);
  // if (!accountHook.isConnected){
  //   console.log(connectHook.connectors);
  //   connectHook.connect({connector: connectHook.connectors[0]});
  //   if (!connectHook.isSuccess) {
  //     console.log(connectHook.error);
  //     return null;
  //   }
  // }
  // console.log(accountHook.address);
  // await web3.send("eth_requestAccounts", []);
  // const signer = web3.getSigner();
  signature = await signMessage.signMessageAsync({
    message: `Miawwhalla Login.\nAuthentication Only!\nThis action will not cause any financial mutation.\n\nMIAWWHALLA:${pubkey}:${nonce + 1}`,
  });
  console.log(signature);
  return signature;
};

const _connectWallet = async (connectHook, connector) => {
  await connectHook.connectAsync({ connector: connector == "WalletConnect" ? connectHook.connectors[1] : connectHook.connectors[0] });
};

export const useWeb3Auth = () => {
  // if (!window.ethereum) throw "No Ethereum Provider Found";

  const accountHook = useAccount();
  const connectHook = useConnect();
  const signMessage = useSignMessage();

  return {
    doConnectWallet: (preferredWallet) => _connectWallet(connectHook, preferredWallet),
    doWeb3Auth: (pubkey, nonce) => _signMessage(signMessage, pubkey, nonce),
    isConnecting: accountHook.isConnecting,
    isConnected: accountHook.isConnected,
    connectedAddress: accountHook.address?.toLowerCase(),
    //   doInitAuth: (pubkey) => initAuth(pubkey),
  };
};

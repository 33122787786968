import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { useAuthModal } from "../context/store";
import { useWeb3Auth } from "../web3/eth/auth";
import LoadingSpinner from "./LoadingSpinner";
import toast from "react-hot-toast";
import { useAuthenticatedHttpClient } from "../utils/authenticated-api-call";
import { useDisconnect } from "wagmi";

export default function AuthModal() {
  const { disconnect } = useDisconnect()
  const { internalAPIClient } = useAuthenticatedHttpClient();
  const { doWeb3Auth, doConnectWallet, isConnected, connectedAddress } = useWeb3Auth();

  const showModal = useAuthModal((state) => state.show);
  const setModal = useAuthModal((state) => state.setShow);
  const [isLoading, setIsLoading] = useState(false);
  
  useEffect(async () => {
    const auth = localStorage.getItem("x-auth-khuga");
    if (connectedAddress !== undefined && (auth === null || auth === undefined)) {
      // web3 verify
      console.log("Initiating Web3 Authentication");
      const nonce = await internalAPIClient.get(`/web3/auth?public_key=${connectedAddress}`).catch((e) => {
        setIsLoading(false);
        handleFailedVerify();
        return toast.error("Authentication Error: " + e);
      });

      console.log(`Nonce is: ${nonce?.data?.data?.nonce}`);

      if (nonce?.data?.data?.nonce !== undefined) {
        // Sign Message
        setIsLoading(true);
        console.log("Please Sign the Authentication with Wallet");
        try {
          const signature = await doWeb3Auth(connectedAddress, nonce?.data?.data?.nonce);

          // Verify
          setIsLoading(true);
          console.log("Verifying Message Signing");
          const verify = await internalAPIClient
            .post("/web3/verify-miawwhalla", {
              public_key: connectedAddress,
              signature: signature,
            })
            .catch((e) => {
              // wallet.disconnect();
              toast.error("Authentication Error: " + e);

              setTimeout(() => {
                setIsLoading(false);
                handleFailedVerify();
              }, 1000);
            });

          setIsLoading(false);
          console.log(verify);
        } catch (e) {
          toast.error("Authentication Error");
          setTimeout(() => {
            setIsLoading(false);
            handleFailedVerify();
          }, 1000);
          return;
        }
      } else {
        toast.error("Authentication Error");
        setTimeout(() => {
          setIsLoading(false);
          handleFailedVerify();
        }, 1000);
        return;
      }

      toast.success("Wallet connected!");
      setIsLoading(false);
      setModal(false);
      localStorage.setItem("metamask_address", connectedAddress);
      window.location.reload();
    }
  }, [connectedAddress]);

  const handleConnectWallet = async (provider) => {
    setIsLoading(true);

    if (provider === "MetaMask" && !window.ethereum) {
      setIsLoading(false);
      return toast.error("Install metamask extension!");
    }

    try {
      if (!isConnected) await doConnectWallet(provider);
      console.log(connectedAddress);
    } catch (e) {
      console.log(e);
      toast.error("Failed to connect to wallet");
      return setIsLoading(false);
    }
  };

  const handleFailedVerify = () => {
    disconnect();
    localStorage.removeItem("x-auth-khuga");
    localStorage.removeItem("x-user-khuga");
    localStorage.removeItem("metamask_address");
  }

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <Transition appear show={showModal} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => setModal(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/50 backdrop-blur" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-xl font-bold leading-6 text-gray-900 mb-4">
                    Connect Your Wallet
                  </Dialog.Title>
                  <div className="mt-2 space-y-2">
                    <button
                      onClick={() => handleConnectWallet("MetaMask")}
                      className="hidden md:flex items-center justify-center w-full bg-primary-800 text-white p-3 rounded-md"
                    >
                      Connect with MetaMask
                    </button>
                    <button
                      onClick={() => handleConnectWallet("WalletConnect")}
                      className="flex items-center justify-center w-full bg-primary-800 text-white p-3 rounded-md"
                    >
                      Connect with WalletConnect
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
